<template>
  <v-carousel 
    v-if="testimonials"
    show-arrows-on-hover
    v-model="carousel"
    height="100%" 
    continuous
    cycle
  >
    <v-carousel-item
      v-for="(testimonial, i) in Object.values(testimonials)"
      :src="testimonial.src"
      :key="i"
    >
      <div class="d-flex fill-height fill-width flex-wrap">
        <div class="flex-grow-1 full-width">
          <v-img 
            src="@/assets/Lightning-Sites-Logo-white.svg" 
            class="carousel-logo"
            width="250"
            cover
            left
          />
        </div>

        <div class="align-self-end pb-7 carousel-description">
          <div>
            <div class="testimonial heading-font">
              {{ testimonial.testimonial }}
            </div>

            <div class="author heading-font">{{ testimonial.author }}</div>
            <div class="author-title heading-font">{{ testimonial.title }}</div>
          </div>
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import firebase from 'firebase'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      images: [],
      carousel: null,
      testimonials: null,
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getTestimonials() {
      let remoteConfig = firebase.remoteConfig()
      remoteConfig.settings.minimumFetchIntervalMillis = 15000
      
      remoteConfig.fetchAndActivate()
      .then(() => {
        const testimonials = remoteConfig.getValue('testimonials')
        this.testimonials = JSON.parse(testimonials._value)

        Object.values(this.testimonials).forEach((testimonial, i) => {
          this.images[i] = new Image()
          this.images[i].src = testimonial.src
        })
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getTestimonials()
  }
}
</script>

<style lang="scss">
.carousel {

  &-description {
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    position: relative;
    height: 50%;
    width: 100%;

    & > div {
      bottom: 156px;
      position: absolute;
      padding-left: 100px;
      padding-right: 100px;
      $text-shadow: 0 2px 2px RGBA(0, 0, 0, 0.5);

      .testimonial {
        color: #fff;
        font-size: 22px;
        margin-bottom: 40px;
        text-shadow: $text-shadow;
      }

      .author {
        color: #fff;
        font-size: 28px;
        font-weight: bold;
        text-shadow: $text-shadow;
      }
      
      .author-title {
        font-size: 23px;
        text-shadow: $text-shadow;
        color: rgba(255, 255, 255, 0.8);
      }

    }
  }

  &-logo {
    margin-top: 70px;
    margin-left: 100px;
  }

}

.v-carousel__controls {
  background: transparent !important;
  justify-content: flex-end;
  margin-bottom: 85px;
  padding-right: 80px;
}

.v-btn--icon.v-size--small {
  height: 18px;
  width: 18px;
  border: 2px solid #fff;
}

.theme--dark.v-btn--active:hover::before, 
.theme--dark.v-btn--active::before {
  opacity: 1;
}

.v-carousel__controls__item .v-icon {
  opacity: 0;
}
</style>